<template>
  <div class="boosted-post">
    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <div
          style="
            margin: auto;
            padding: 30px;
            border: 1px solid #eee;
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);
            font-size: 16px;
            line-height: 24px;
            font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
            color: #555;"
        >
          <table cellpadding="0" cellspacing="0">
            <tr class="top">
              <td colspan="2">
                <table>
                  <tr>
                    <td class="title">
                      <img
                        src="../../asset/logo.png"
                        style="width:50%; max-width:300px;"
                      />
                    </td>

                    <td>
                      Invoice #: {{ pdfContent.id }}<br />
                      Created: {{ todayDate }}<br />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr class="heading">
              <td>
                <table>
                  <tr>
                    <td>
                      <b>{{ pdfContent.payeeInfo.dealerName }}</b
                      ><br />
                      {{ pdfContent.payeeInfo.name }}<br />
                      {{ pdfContent.payeeInfo.email }}<br />
                      {{ pdfContent.payeeInfo.phone }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr class="heading">
              <td>Payment Method : {{ pdfContent.paymentType }}</td>
            </tr>

            <tr class="heading">
              <td>
                <b>Item</b>
              </td>

              <td>
                <b>Price</b>
              </td>
            </tr>

            <tr class="item">
              <td>
                <a
                  :href="'https://ucars.sg/used-cars/' + pdfContent.carInfo.id"
                  >{{ pdfContent.carInfo.productTitle }}</a
                ><br />
                {{ pdfContent.item }}
              </td>

              <td>
                {{ pdfContent.payable }}
              </td>
            </tr>

            <tr class="total">
              <td></td>

              <td>
                <b>Total: ${{ pdfContent.payable }}</b>
              </td>
            </tr>
          </table>
        </div>
      </section>
    </vue-html2pdf>
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editBumper.name }}
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="editBumper.status"
            :items="bumperStatusList"
            item-value="value"
            item-text="text"
            label="Pricing Status"
          >
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditBumper()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchBumper()"
          v-model="bumperSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="bumperStatus"
          :items="bumperStatusList"
          item-value="value"
          item-text="text"
          label="Ads Status"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchBumper()"
          @keyup.enter.native="searchBumper()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="bumperList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr>
          <td>{{ row.item.id }}</td>
          <td>{{ row.item.payeeInfo.dealerName }}</td>
          <td>
            <a
              :href="'https://ucars.sg/used-cars/' + row.item.carInfo.id"
              target="_blank"
            >
              {{ row.item.carInfo.productTitle }}</a
            >
          </td>
          <td>{{ row.item.item }}</td>
          <td>
            {{ row.item.payeeInfo.name }}, {{ row.item.payeeInfo.phone }},
            {{ row.item.payeeInfo.email }}
          </td>
          <td>$ {{ row.item.payable }}</td>
          <td>{{ row.item.paymentType }}</td>
          <td>{{ row.item.createTime }}</td>
          <td>{{ row.item.status }}</td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="orange"
              @click="generateReport(row.item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </td>
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";

import VueHtml2pdf from "vue-html2pdf";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      bumperSearch: "",
      bumperStatus: "",
      productURL: "",
      token: "",
      pageCount: 0,
      editDialog: false,
      pdfContent: {
        id: "",
        payeeInfo: {
          dealerName: "",
          name: "",
          email: "",
          phone: "",
        },
        carInfo: {
          id: "",
          productTitle: "",
        },
      },
      todayDate: "",
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Dealer Name", value: "dealerName" },
        { text: "Product", value: "productTitle" },
        { text: "Item", value: "item" },
        { text: "Initiator", value: "initiator" },
        { text: "Amount", value: "payable" },
        { text: "Payment Method", value: "paymentType" },
        { text: "Created Time", value: "createTime" },
        { text: "Status", value: "status" },
        { text: "Invoice" },
        { text: "Edit" },
      ],
      bumperList: [],
      editBumper: {
        name: "",
        bumperId: "",
        status: "",
      },
      bumperStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchBumperList();
    this.getTodayDate();
  },
  methods: {
    searchBumper() {
      this.fetchBumperList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchBumperList();
    },
    editButtonClick(item) {
      this.editBumper.name = item.name;
      this.editBumper.status = item.status;
      this.editBumper.bumperId = item.id;

      this.editDialog = true;
    },
    async doEditBumper() {
      const self = this;

      try {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          this.editBumper,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.editDialog = false;
            self.fetchBumperList();
            self.$message({
              message: "Dealer Edited Successfully!",
              type: "success",
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    fetchBumperList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.bumperSearch,
        status: self.bumperStatus,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/bumperSystem/invoice`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.bumperList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    generateReport(information) {
      this.pdfContent = information;
      this.$refs.html2Pdf.generatePdf();
    },
    getTodayDate() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let dateObj = new Date();
      let month = monthNames[dateObj.getMonth()];
      let day = String(dateObj.getDate()).padStart(2, "0");
      let year = dateObj.getFullYear();
      let output = month + "\n" + day + "," + year;
      this.todayDate = output;
    },
  },
  components: {
    VueHtml2pdf,
  },
};
</script>
